const API_URL = "https://grati-preprod.resourcifi.tech/grati/api/";
// const API_URL = "http://54.175.164.74:5000/api/";


const API = {
  FIND_USER: API_URL + "gratitude/findUser",
  CREATE_GRATITUDE: API_URL + "gratitude/createGratitude",
  IMG_GRATITUDE: API_URL + "gratitude/createImageGratitude",

  CREATE_WEB :API_URL+"user/v2/createWebUser",
  PIFORBOTH:API_URL+"payment/pi-forBoth",
  USERPROFILECLICK:API_URL+"user/userProfileClick",
  CREATE_PAYMENT_INTENT: API_URL+"payment/create-payment-intent",
  WEB_PROD_URL:"http://user.grati.community/user_profile",
  WEB_STAGING_URL:"http://user.resourcifi.tech/user_profile",
  STRIPE_LIVE_KEY :"pk_live_51NhHNZFDxlsdZq5eOTANpdboJTGmN9t3tSlXaYHvpxpNISVKNADW633qbIo4axnkwoNK0l0J9PTGTIeRuOT5TPsO00o22sYbza",
  STRIPE_TEST_KEY:"pk_test_51NhHNZFDxlsdZq5eQisMgNxM7vDvVG9SO0Le60jOpQa6KwSxbu9xiP9D8B5oyUQrbn25bu6SBlNhedgMWgSkwaDq00KXlVG4TR",
  ENV: "production", // staging, production, preprod, testing
  // TODO: do the same for Stripe keys in code, make a function getStripeKey
  getPaymentReturnURL: (userId) => {
    switch (API.ENV) {
      case "staging":
        return `${API.WEB_STAGING_URL}/${userId}`;
      case "production":
        return `${API.WEB_PROD_URL}/${userId}`;
      default:
        return `${API.WEB_PROD_URL}/${userId}`;
    }
  }
};
export default API;
