import React from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import API from '../API/urlConstants';
import { ExpressCheckoutElement } from '@stripe/react-stripe-js';

const ApplePayCheckout = ({ selectedAmount, feeAmount, payerDetails }) => {
    const stripe = useStripe();
    const elements = useElements();

    /**
     * Creates a payment intent with the specified amount and payment method.
     */
    const createPaymentIntent = async (selectedAmount, paymentMethod = "card") => {

        if (!["card"].includes(paymentMethod)) {
            throw new Error("Invalid payment method. Please provide a valid payment method. Supported payment methods are: card");
        }
        // let reviewTitle =   fullName + " " + "Tip to" + " " + payerDetails?.fullName;
        const { fullName, email } = payerDetails;
        const thanksUserId = localStorage.getItem("userid");
        const formData = {
            paymentMethodType: paymentMethod,
            piDescription: "cardToWallet",
            metadata: {
                getThanksUserID: thanksUserId,
                title: "Tip to",
                guestName: fullName,
                email: email,
                amountAfterFee: `${feeAmount}`,
            },
            amount: `${selectedAmount}`,
            platformFeeForWallet: `${feeAmount - selectedAmount}`
        };

        const response = await fetch(
            API.CREATE_PAYMENT_INTENT,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer guest",
                },
                body: JSON.stringify(formData),
            }
        );
        const data = await response.json();
        return data?.data;
    };

    const onConfirmApplePay = async (event) => {

        if (!stripe) {
            // Stripe.js hasn't loaded yet.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            //   setAmountValidationError(submitError.message);
            // TODO: Show the error message to the user
            alert(submitError.message);
            return;
        }

        // Create the PaymentIntent and obtain clientSecret
        const data = await createPaymentIntent(selectedAmount);
        const { paymentIntent: clientSecret } = data;
        const thanksUserId = localStorage.getItem("userid");
        // Confirm the PaymentIntent using the details collected by the Express Checkout Element
        const { error } = await stripe.confirmPayment({
            // `elements` instance used to create the Express Checkout Element
            elements,
            // `clientSecret` from the created PaymentIntent
            clientSecret,
            confirmParams: {
                return_url: API.getPaymentReturnURL(thanksUserId),
              },
        });

        if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer (for example, payment details incomplete)
            //   setAmountValidationError(error.message);
            // TODO: Show the error message to the user
            alert(error.message);
        } else {
            // The payment UI automatically closes with a success animation.
            // Your customer is redirected to your `return_url`.
        }

    }
    return (
        <ExpressCheckoutElement
            onConfirm={onConfirmApplePay}
            onClick={(event) => {
              event.resolve({
                emailRequired: false,
                shippingAddressRequired: false,
                billingAddressRequired: false,
                phoneNumberRequired: false,
                // paymentMethodType: "apple_pay"
              })
            }}
            options={
              {
                paymentMethods: {
                  applePay: "always",
                }
              }
            }
          />
    );
};

export default ApplePayCheckout;