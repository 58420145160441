import React, { useState, useEffect } from "react";
import bank from "../Assets/bank.png";
import card from "../Assets/card.png";
import applePay from "../Assets/applePayIcon.png"
import { loadStripe } from "@stripe/stripe-js";
import Joi from "joi";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { createweb } from "../API/api_endpoints";
import API from "../API/urlConstants";
import {ExpressCheckoutElement} from '@stripe/react-stripe-js';
import { useStripe, useElements } from "@stripe/react-stripe-js";
import ApplePayCheckout from "./ApplePayCheckout";
const amountOptions = [10, 15, 20];


const PaymentOptions = ({ fullName, email, userDetails }) => {
  const navigate = useNavigate()
  const [stripePromise, setStripePromise] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [amountValidationError, setAmountValidationError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const [feeAmount, setFeeAmount] = useState("3.0%+$0.50 fee applies");
  const [bankfeeAmount, setBankFeeAmount] = useState("1.00% fee applies");

  useEffect(() => {
    const loadStripeAsync = async () => {
      const stripe = await loadStripe(
        API.STRIPE_TEST_KEY
      );
      setStripePromise(stripe);
    };

    loadStripeAsync();

    
  }, []);

  useEffect(() => {
    if (selectedAmount) {
      setBankFeeAmount(calculateBankAmountAfterFees(selectedAmount));
      setFeeAmount(calculateAmountAfterFees(selectedAmount));
      // createPaymentIntent(selectedAmount);
    }
  }, [selectedAmount]);

  const paymentMethods = [
    {
      src: card,
      label: "Pay via Debit/Credit Card",
      value: "card",
      amount: selectedAmount ? `$${feeAmount}` : "3.0%+$0.50"
  
    },
    {
      src: bank,
      label: "Pay via Bank",
      value: "us_bank_account",
      amount: selectedAmount ? `$${bankfeeAmount}` : "1.00%"
  
    },
   /*  {
      src: applePay, // Add the Apple Pay icon or image source
      label: "Pay via Apple Pay",
      value: "apple_pay", // This value will be used to identify the payment method
      amount: selectedAmount ? `$${feeAmount}` : "3.0%+$0.50"
      // amount: "12", // Adjust this as necessary; Apple Pay typically varies by transaction
    }, */
  ];

  const handleAmountChange = (event) => {
    const inputValue = parseInt(event?.target?.value);
      if (!isNaN(inputValue)) {
      setSelectedAmount(inputValue);
      setAmountValidationError(null);
    } else {
      setSelectedAmount(null);
      setAmountValidationError("Please enter a valid amount.");
    }
    // setBankFeeAmount(calculateBankAmountAfterFees(parseInt(event?.target?.value)));
    // setFeeAmount(calculateAmountAfterFees(parseInt(event?.target?.value)));

    // createPaymentIntent(inputValue, "card");
  };

  const handleAmountButtonClick = (amount) => {
    setSelectedAmount(amount);
    setAmountValidationError(null);
  };

  console.log({ selectedAmount })
  const calculateAmountAfterFees = (amount) => {
    const stripePercent = 2.9 / 100;
    const stripeFixedFee = 0.3;
    const appPercent = 0.1 / 100;
    const appFixedFee = 0.2;
         // Calculate Stripe fees
    const stripeFees = amount * stripePercent + stripeFixedFee;
    // Calculate app fees
    const appFees = amount * appPercent + appFixedFee;
    // Calculate the total fees
    const totalFees = stripeFees + appFees;
    const amountAfterFees = Number(amount) + totalFees;
    return amountAfterFees.toFixed(1);
  };

  const calculateBankAmountAfterFees = (paymentAmount) => {
    const stripeFeePercentage = 0.8 / 100;
    const appFeePercentage = 0.2 / 100;
    // Calculate fees
    const stripeFees = paymentAmount * stripeFeePercentage;
    const appFees = paymentAmount * appFeePercentage;
    // Calculate the total fees
    let totalFees = stripeFees + appFees;
    if (totalFees > 10) {
      totalFees = 10;
    }
    // Calculate the amount after deducting all fees
    const amountAfterFees = Number(paymentAmount) + totalFees;
    return amountAfterFees.toFixed(1);
  };


  const handlePaymentClick = async (
    stripePromise,
    selectedAmount,
    paymentMethod
  ) => {
    console.log("callleddd")
    const amountSchema = Joi.number().integer().min(0).required();
    const { error } = amountSchema.validate(selectedAmount);

    if (error) {
      setAmountValidationError("Please enter a valid amount.");
      return;
    }

    if (!stripePromise || !selectedAmount || !paymentMethod) {
      console.error(
        "Missing required information: amount or card payment method"
      );
      return;
    }
    // let fee;
    if (paymentMethod === "us_bank_account") {
      setFeeAmount(calculateBankAmountAfterFees(selectedAmount));
    } else if (paymentMethod === "card") {
      setFeeAmount(calculateAmountAfterFees(selectedAmount));
    }

    let reviewTitle =   fullName + " " + "Tip to" + " " + userDetails?.fullName;
    console.log({reviewTitle})

    const IdVal = localStorage.getItem("userid");

    const formData = {
      paymentMethodType: paymentMethod,
      piDescription: "cardToWallet",
      metadata: {
        getThanksUserID: IdVal,
        title: "Tip to",
        guestName: fullName,
        email: email,
        amountAfterFee: `${feeAmount}`,
      },
      amount: `${selectedAmount}`,
      platformFeeForWallet: `${feeAmount - selectedAmount}`
    };

    const response = await fetch(
      API.CREATE_PAYMENT_INTENT,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer guest",


        },
        body: JSON.stringify(formData),
      }
    );
    const data = await response.json();
    console.log({ data })
     // Check for Apple Pay payment method
  
    setClientSecret(data?.data?.paymentIntent);
    setCheckout(true);
    console.log({ IdVal })
  
    navigate(`/user_profile/${IdVal}`);
  };
  
  useEffect(() => {
    const fetchData = async () => {

      const formData = {
        email: email,
        fullName: fullName
      };

      try {
        const response = createweb(formData).then((response) =>
          console.log(response, "data")
    
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Payment Intent created successfully:', data);
        // Handle the response data here
        // if (data?.userId) {
        //   const IdVal = localStorage.getItem("userid");

        //   const formData = {
        //     userProfileId: IdVal

        //   };

        //   try {
        //     const response = await fetch(
        //       "http://54.175.164.74:5000/api/user/userProfileClick",
        //       {
        //         method: "POST",
        //         headers: {
        //           "Content-Type": "application/json",
        //           Authorization: "Bearer guest",
        //         },
        //         body: JSON.stringify(formData),
        //       }
        //     );

        //     if (!response.ok) {
        //       throw new Error(`HTTP error! status: ${response.status}`);
        //     }

        //     const data = await response.json();
        //     console.log('Payment Intent created successfully:', data);
        //     // Handle the response data here

        //   } catch (error) {
        //     console.error('Error creating Payment Intent:', error);
        //     // Handle any errors here
        //   }
        // }
      } catch (error) {
        console.error('Error creating Payme nt Intent:', error);
        // Handle any errors here
      }
    };

    fetchData(); // Call the async function
  }, [navigate])
  return (
    <div className="w-full flex flex-col text-center px-[2rem] pb-8 gap-4">
      <div className="flex flex-col gap-8">
        <div className="flex flex-row flex-wrap justify-between gap-2">
          {amountOptions.map((item) => (
            <button
              key={item}
              className={`h-[3.125rem] w-[28%] flex border border-[#979797] rounded-[0.625rem] justify-center border-opacity-[30%] items-center ${selectedAmount === item ? "bg-[#e0e0e0]" : ""
                }`}
              value={item}
              onClick={() => handleAmountButtonClick(item)}>
              <p className="text-[#979797] text-base font-medium">{`$${item}`}</p>
            </button>
          ))}
        </div>

        <div className="flex flex-col gap-2 w-full ">
          <input
          onKeyPress={(e) => {
            if (e.key === '.' || e.key === ',' || e.key === 'e') {
              e.preventDefault(); // Prevent the dot (.) and other invalid characters
            }
          }}
          onPaste={(e) => {
            const pastedData = e.clipboardData.getData('text');
            if (pastedData.includes('.') || pastedData.includes(',') || isNaN(pastedData)) {
              e.preventDefault(); // Prevent pasting if it contains a dot or non-numeric data
            }
          }}
            type="number"
            id="amount"
            min={0}
            step={1}
            className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%] font-light h-[3.125rem] text-base p-[0.625rem] ${amountValidationError ? "border-red-500" : ""
              }`}
            placeholder="Other (in Dollars)"
            value={selectedAmount || ""}
            onChange={handleAmountChange}
          />
          {amountValidationError && (
            <p className="w-full self-start  text-red-500 text-sm text-left">
              {amountValidationError}
            </p>
          )}
          
        </div>
      </div>
      {paymentMethods.map((items) => {
        return (
          <>
            <button
              className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%] h-[3.125rem] flex items-center gap-2 p-[0.625rem] ${paymentMethod === items?.value ? "bg-[#e0e0e0] border-[#000000]" : "bg-white"
                }`}
              value={items?.value}
              key={items?.value}
              onClick={() => {
                setPaymentMethod(items?.value);
                handlePaymentClick(stripePromise, selectedAmount, items?.value);
              }}>
              <img src={items.src} alt="" className="w-[1.5rem] h-[1.5rem]" />
              <p className="text-[#1D1546] font-medium text-sm">{items.label}</p>
            </button>

            {
            <p className="w-full self-start  text-green-700 text-sm text-left">
              {`You will be charged ${items?.amount}`}
            </p>
          }
          </>
        );
      })}
      {stripePromise && clientSecret && checkout ? (

        <Elements
          stripe={stripePromise}
          key={clientSecret}
          options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>) : null}
        
        { stripePromise && selectedAmount &&
        <Elements
          stripe={stripePromise}
          options={{ 
            mode: "payment",
            amount: parseFloat(feeAmount) * 100,
            currency: "usd",
            paymentMethodTypes: ["card"],
            // onBehalfOf: userDetails?.stripeAccountId,
           }}>
            <ApplePayCheckout selectedAmount={selectedAmount} feeAmount={feeAmount} payerDetails={{email,fullName}} />
        </Elements>
        }

    </div>
  );
};

export default PaymentOptions;
