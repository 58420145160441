import axios from "./axiosConfig";
import API from "./urlConstants";

export async function wrappedFetch(url, method, data, headers, configs) {
  let config = {
    url,
    method,
    data,
    ...configs,
  };
  if (headers) config.headers = headers;
  try {
    let result = await axios(config);
    return result;
  } catch (err) {
    console.log({err})
    // throw new Error(
    //   err?.response?.data?.message
    //     ? err?.response?.data?.message
    //     : "Failed to fetch data , some error occured"
    // );
  }
}

export async function wrappedGet(url, method, params) {
  let config = {
    url,
    method,
    headers: {},
    params,
  };

  try {
    return await axios(config);
  } catch (err) {
    throw new Error(
      err?.response?.data?.message
        ? err?.response?.data?.message
        : "Failed to fetch data , some error occured"
    );
  }
}
