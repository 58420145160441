import React, { useEffect, useState } from "react";
import { useMediaQuery, Drawer, MenuItem } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import sms from "../Assets/sms.png";
import facebook from "../Assets/facebook.png";
import instagram from "../Assets/instagram.png";
import linkedin from "../Assets/linkedin.png";
import twitter from "../Assets/twitter.png";
import logo from "../Assets/grati-logo.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import branch from 'branch-sdk'; // Import the Branch SDK for web
import { findUserByQR } from "../API/api_endpoints";

const NavBar = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const params = useParams()
  const searchParams = useSearchParams()
  const { id } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [feedDetails, setFeedDetails] = useState([]);
  const [scanData, setScanData] = useState({})
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleSupportEmail = () => {
    window.location.href = "mailto:support@grati.community";
  };
  const handleHome = () => {
    window.location.href = "https://grati.community/";
  };


  useEffect(() => {

    branch.init('key_test_nuhRPTcqs2pXIre2P2WApipbtteTPMkT', (err, data) => {
      const originalUrl = data?.data_parsed['~referring_link'];
      if (err) {
        setError("Failed to initialize Branch SDK");
        setLoading(false);
        return;
      }

      setScanData(data)
      if (data && data?.data_parsed && data?.data_parsed?.dynamicKey) {
        const incomingId = data?.data_parsed?.dynamicKey;
        localStorage.setItem("userId", incomingId)
        if (incomingId) {
          navigate(`/user_profile/${incomingId}`);
        }
      }

      setLoading(false);
    });

    if (id) {
      findUserByQR({ _id: id, profilePage: true })
        .then((response) => {
          setUserDetails(response?.data?.data?.userDetails);
          setFeedDetails(
            response?.data?.data?.userFeed
              .filter((item) => item.gratitudeType === "REVIEW")
              .slice(0, 4)
          );
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
          setError("Failed to fetch user details");
          setLoading(false);
        });
    }



  }, []);



  return (
    <div className="flex flex-col items-center justify-center  w-full">
      <div
        className={`flex flex-wrap justify-between border-b border-solid border-white border-opacity-25 py-3 w-full `}>
        <div className="flex gap-2" onClick={handleSupportEmail}>
          <img src={sms} alt="" className="h-[1.25rem] w-[1.25rem]" />
          <p className="font-semibold text-sm text-[#C1BDD6] hover:cursor-pointer">
            support@grati.community
          </p>
        </div>
        <div className="flex gap-4">
          <a href="https://www.facebook.com/grati.community" target="_blank">
            <img
              src={facebook}
              alt=""
              className="h-[1.25rem] w-[1.25rem] hover:cursor-pointer"
            />
          </a>
          <a h href="https://www.instagram.com/grati.community" target="_blank">
            <img
              src={instagram}
              alt=""
              className="h-[1.25rem] w-[1.25rem] hover:cursor-pointer"
            />
          </a>
          <a href="" target="_blank">
            <img
              src={linkedin}
              alt=""
              className="h-[1.25rem] w-[1.25rem] hover:cursor-pointer"
            />
          </a>
          <a href="https://twitter.com/grati.community" target="_blank">
            <img
              src={twitter}
              alt=""
              className="h-[1.25rem] w-[1.25rem] hover:cursor-pointer"
            />
          </a>
        </div>
      </div>
      <div className="flex flex-wrap justify-between items-center py-3 w-full">
        <img src={logo} alt="" className="h-[2rem] w-[6.375rem]" />
        {isMobile ? (
          <div>
            <MenuOutlinedIcon
              className="text-white"
              onClick={handleDrawerOpen}
            />
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={handleDrawerClose}>
              <MenuItem onClick={handleHome}>Home</MenuItem>
              <MenuItem onClick={handleHome}>How Grati Works</MenuItem>
              <MenuItem onClick={handleHome}>Download</MenuItem>
              <MenuItem onClick={handleHome}>Contact Us</MenuItem>
            </Drawer>
          </div>
        ) : (
          <div className="flex gap-10 text-white text-sm font-semibold">
            <p
              onClick={handleHome}
              className="hover:border-b hover:border-solid hover:border-[#FF6F29]  hover:text-[#FF6F29] hover:cursor-pointer">
              Home
            </p>
            <p
              onClick={handleHome}
              className="hover:border-b hover:border-solid hover:border-[#FF6F29]  hover:text-[#FF6F29] hover:cursor-pointer">
              How Grati Works
            </p>
            <p
              onClick={handleHome}
              className="hover:border-b hover:border-solid hover:border-[#FF6F29]  hover:text-[#FF6F29] hover:cursor-pointer">
              Download
            </p>
            <p
              onClick={handleHome}
              className="hover:border-b hover:border-solid hover:border-[#FF6F29]  hover:text-[#FF6F29] hover:cursor-pointer    ">
              Contact Us
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
