import React from "react";
import googlePlay from "../Assets/google-play.png";
import appstore from "../Assets/app-store.png";
import star from "../Assets/star.png";

const Download = ({err}) => {
  const redirectToGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.de.grati",
      "_blank"
    );
  };

  const redirectToAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/grati-community/id6464380120",
      "_blank"
    );
  };
  return (
    <div className="flex flex-col gap-10 justify-between w-inherit">
      {!err ?<>
      <div className="flex gap-3 justify-start items-center">
        <img src={star} alt="" className="w-[2rem] h-[2rem] " />
        <p className="flex flex-wrap text-white text-2xl font-medium">
          Give a little thanks
        </p>
        <img src={star} alt="" className="w-[2rem] h-[2rem] " />
      </div>
      <p className="flex flex-wrap text-white text-base font-normal leading-[26px]">
        Grati makes the recognition process simple and meaningful, building
        strong connections in communities. Celebrate those that help you most,
        build a brand around your people, and shape a world where gratitude
        becomes a powerful force one 'thank you' at a time.
      </p>
      <div className="flex flex-wrap gap-4 justify-start p-0 m-0">
        <button
          className="flex w-[10rem] h-[3rem] items-center justify-around bg-white shadow-lg rounded-3xl"
          onClick={redirectToGooglePlay}>
          <img src={googlePlay} alt="Google Play Store" />
        </button>
        <button
          className="flex w-[10rem] h-[3rem] items-center justify-around bg-white shadow-lg rounded-3xl"
          onClick={redirectToAppStore}>
          <img src={appstore} alt="Apple App Store" />
        </button>
      </div></>:""}
    </div>
  );
};

export default Download;
