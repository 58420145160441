import React, { useEffect, useState } from "react";
import Joi from "joi";
import bank from "../Assets/bank.png";
import card from "../Assets/card.png";
import recognition from "../Assets/recognition.png";
import appreciation from "../Assets/appreciation.png";
import celebration from "../Assets/celebration.png";
import gratitude from "../Assets/gratitude.png";
import gift from "../Assets/gift.png";
import gallery from "../Assets/gallery.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { loadStripe } from "@stripe/stripe-js";
import { CircularProgress, Snackbar, TextField, useMediaQuery } from "@mui/material";
import { createweb, giveImageReview, giveReview, PIForBoth, USERPROFILECLICK } from "../API/api_endpoints";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { findUserByQR } from "../API/api_endpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../API/urlConstants";
import ApplePayCheckout from "./ApplePayCheckout";

const schema = Joi.object({
  sayThanks: Joi.string().trim().max(250).allow("").label("Say Thanks"),
  post: Joi.array().items(Joi.any()).label("Post").default([]),
})
  .or("sayThanks", "post")
  .error((errors) => {
    console.log({errors})
    return new Error('Either "Say Thanks" or "Post" must be provided.');
  });

const reviewTypes = [
  { src: recognition, label: "Recognition", value: "RECOGNITION" },
  { src: appreciation, label: "Appreciation", value: "APPRECIATION" },
  { src: celebration, label: "Celebration", value: "CELEBRATION" },
  { src: gratitude, label: "Gratitude", value: "GRATITUDE" },
];


const Review = ({ review, fullName, email }) => {
  console.log({ review })
const location = useLocation()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [sayThanks, setSayThanks] = useState("");
  const [addGift, setAddGift] = useState(false);
  const [gratitude, setGratitude] = useState(review);
  const [errors, setErrors] = useState({});
  const [post, setPost] = useState([]);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [amountValidationError, setAmountValidationError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const[inputVal,setInputVal]=useState("")
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [feeAmount, setFeeAmount] = useState("3.0%+$0.50 fee applies");
  const [bankfeeAmount, setBankFeeAmount] = useState("1.00% fee applies");
  const [thumbnail, setThumbnail] = useState("");
  const [load, setLoad] = useState(false);
  const [message,setMessage]=useState({});

  const paymentMethods = [
    {
      src: card,
      label: "Pay via Debit/Credit Card",
      value: "card",
      amount: selectedAmount ? `$${feeAmount}` : "3.0%+$0.50"
    },
    {
      src: bank,
      label: "Pay via Bank",
      value: "us_bank_account",
      amount: selectedAmount ? `$${bankfeeAmount}` : "1.00%"
    },
  ];


  useEffect(()=>{
if(inputVal){
  setAmountValidationError("");

    handlePaymentClick(   stripePromise,
      selectedAmount,
      paymentMethod)
  
}
  },[inputVal])
  
  useEffect(() => {
    findUserByQR({
      _id: id,
      profilePage: true,
    })
      .then((response) => {
        setUserDetails(response?.data?.data?.userDetails);
      })
      .catch((error) => { });
    const loadStripeAsync = async () => {
      const stripe = await loadStripe(
        API.STRIPE_TEST_KEY
      );
      setStripePromise(stripe);
    };

    loadStripeAsync();
  }, []);

  const handleGratitudeClick = (item) => {
    setGratitude(item);
  };

  const handleGiftClick = () => {
    setAddGift(!addGift);
  };

  const handleSayThanksChange = (e) => {
    setSayThanks(e?.target?.value);
    setErrors((prevErrors) => ({ ...prevErrors, sayThanks: "" }));
  };

  const handleAmountChange = (event) => {
    const inputValue = parseInt(event?.target?.value);
    setInputVal(inputValue)
    console.log({inputValue})
  
    if (!isNaN(inputValue)) {
      setSelectedAmount(inputValue);
      setAmountValidationError(null);
    } else {
      setSelectedAmount(null);
      setAmountValidationError("Please enter a valid amount.");
    }
    setBankFeeAmount(calculateBankAmountAfterFees(parseInt(event?.target?.value)));
    setFeeAmount(calculateAmountAfterFees(parseInt(event?.target?.value)));

  };

  const calculateAmountAfterFees = (amount) => {
    const stripePercent = 2.9 / 100;
    const stripeFixedFee = 0.3;
    const appPercent = 0.1 / 100;
    const appFixedFee = 0.2;
    const stripeFees = amount * stripePercent + stripeFixedFee;
    const appFees = amount * appPercent + appFixedFee;
    const totalFees = stripeFees + appFees;
    const amountAfterFees = Number(amount) + totalFees;
    return amountAfterFees.toFixed(1);
  };

  const calculateBankAmountAfterFees = (paymentAmount) => {
    const stripeFeePercentage = 0.8 / 100;
    const appFeePercentage = 0.2 / 100;
    const stripeFees = paymentAmount * stripeFeePercentage;
    const appFees = paymentAmount * appFeePercentage;
    let totalFees = stripeFees + appFees;
    if (totalFees > 10) {
      totalFees = 10;
    }
    const amountAfterFees = Number(paymentAmount) + totalFees;
    return amountAfterFees.toFixed(1);
    
  };
  const handlePaymentClick = async (
    stripePromise,
    selectedAmount,
    paymentMethod
  ) => {
    setAmountValidationError(null);
    const validationResults = schema.validate(
      { sayThanks, post },
      { abortEarly: false }
    );
if(video?.url ===""){
    if (validationResults.error) {
      const newErrors = {};
      validationResults.error.details?.forEach((detail) => {
        newErrors[detail.context.key] = detail.message;
      });

      setErrors(newErrors);
      return;
    }}
    if (selectedAmount !== null && selectedAmount !== undefined) {
      const amountSchema = Joi.number().integer().min(1).required();
      const { error } = amountSchema.validate(selectedAmount);
      if (error) {
        setAmountValidationError("Please enter a valid amount.");
        return;
      }
    }
    console.log({selectedAmount})
    if (!stripePromise || !selectedAmount || !paymentMethod) {

      // setAmountValidationError("Please enter a valid amount.")
      console.error(
        "Missing required information: amount or card payment method"
      );
      return;
    }
    // let amount;
    // if (paymentMethod === "us_bank_account") {
    //   setFeeAmount(calculateBankAmountAfterFees(selectedAmount));
    // } else if (paymentMethod === "card") {
    //   setFeeAmount(calculateAmountAfterFees(selectedAmount));
    // }
    setPaymentMethod(paymentMethod);
    const formData = new FormData();
    const IdVal = localStorage.getItem("userid");

    formData.append("paymentMethodType", paymentMethod);
    formData.append("piDescription", "cardToWallet");
    formData.append("metadata[getThanksUserID]", IdVal);
    formData.append("metadata[guestName]", fullName);
    formData.append("metadata[email]", email);
    formData.append("metadata[amountAfterFee]", selectedAmount);

    formData.append("metadata[title]", "Tip to");

    formData.append("gratitudeType", "BOTH");
    formData.append("amount", selectedAmount);
    formData.append("sayThanks", sayThanks);
    formData.append("title", reviewTitle);
    formData.append("icon", review);
    formData.append("platformFeeForWallet", `${feeAmount - selectedAmount}`)


     let mediaArray = [];
      if (Array.isArray(post)) {
        post?.forEach((item) => {
          formData.append("media", item);
          mediaArray.push({ name: item.name, type: item.type });
        });
      } else {
        formData.append("media", post);
        formData.append("media", dataURItoBlob(thumbnail), "thumbnail.jpg");

        mediaArray.push(
          { name: post.name, type: post.type },
          { name: "thumbnail.jpg", type: "image/jpeg" }
        );
      }
    console.log(formData, "formdata");
    
try {
  

    const response = await fetch(
      `${API.PIFORBOTH}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer guest`,
        },
        body: formData,
      })
    const data = await response.json();
    // setMessage({fileSelect:data?.message})
    console.log({data})
    setClientSecret(data?.data?.paymentIntent);
    setCheckout(true);
    console.log({ IdVal })
    navigate(`/user_profile/${IdVal}`);
  } catch (error) {
  console.log({error})
  }
  };

  console.log({message})
const logFormData = (formData) => {
  for (let [key, value] of formData.entries()) {
    console.log({value})
    if (value instanceof File) {
      console.log(`${key}:`);
      console.log(`  Name: ${value.name}`);
      console.log(`  Type: ${value.type}`);
      console.log(`  Size: ${value.size}`);
      console.log(`  Size: ${value}`);

    } else {
      console.log(`${key}: ${value}`);
    }
  }
};
function dataURItoBlob(dataURI) {
  // Split the data URI into the mime type and the base64 part
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // Create an array buffer to store the binary data
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  
  // Write the binary data to the array buffer
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Create and return a Blob from the array buffer and the MIME type
  return new Blob([ab], { type: mimeString });
}

const handleSubmit = () => {
  setLoad(true); // Set loading state immediately

  setTimeout(() => {
    try {
      const validationResults = schema.validate({ sayThanks, post }, { abortEarly: false });

      if (validationResults?.error && Array.isArray(post)) {
        const newErrors = {};
        validationResults?.error?.details?.forEach((detail) => {
          newErrors[detail?.context?.key] = detail?.message;
        });
        console.log("Validation Errors:", newErrors);
        setErrors(newErrors);
        setLoad(false); // Stop loading state if there's a validation error
        return;
      } else {
        setErrors({});
      }

      let formData = new FormData();
      const IdVal = localStorage.getItem("userid");

      formData.append("getThanksUserId", IdVal);
      formData.append("icon", review);
      formData.append("title", reviewTitle);
      formData.append("sayThanks", sayThanks);

      formData.append("gratitudeType", "REVIEW");     
      
      let mediaArray = [];
      if (Array.isArray(post)) {
        post?.forEach((item) => {
          formData.append("media", item);
          mediaArray.push({ name: item.name, type: item.type });
        });
      } else {
        formData.append("media", post);
        formData.append("media", dataURItoBlob(thumbnail), "thumbnail.jpg");

        mediaArray.push(
          { name: post.name, type: post.type },
          { name: "thumbnail.jpg", type: "image/jpeg" }
        );
      }

      formData.append("accountType", "Guest");
      formData.append("guestName", fullName);
      formData.append("email", email);
      formData.append("latitude", 0);
      formData.append("longitude", 0);

      logFormData(formData);

      if (post?.length) {
        giveImageReview(formData).then((response) => {
          if (response?.status === 200 && IdVal) {
            
            const address = `${API.WEB_STAGING_URL}/${IdVal}?show=true`;
            window.location.href = address;
          }
          setLoad(false); // Stop loading state after submission
        });
      } else {
        
        giveReview(formData).then((response) => {
          if (response?.status === 200 && IdVal) {
            

            const address = `${API.WEB_STAGING_URL}/${IdVal}?show=true`;
            window.location.href = address;
          }
          setLoad(false); // Stop loading state after submission
        });
      }
    } catch (error) {
      console.log({ error });
      setLoad(false); // Stop loading state in case of error
    }
  }, 100); // Adding a slight delay to ensure loading state is set
};


console.log({load})

  const iconValue = (value) => {
    let text = "";
    if (value == "APPRECIATION") {
      text = "appreciated";
    } else if (value == "CELEBRATION") {
      text = "celebrated";
    } else if (value == "RECOGNITION") {
      text = "recognized";
    } else if (value == "GRATITUDE") {
      text = "thanked";
    }
    return text;
  };

  let reviewTitle =
     iconValue(gratitude)
     let titleView=    fullName + " " + iconValue(gratitude) + " " + userDetails?.userName;
     const handleFileSelect = (e) => {
      const file = Array.from(e.target.files);
      if (!file.length) return;
      console.log({file})
  
      setErrors({});
      const validFiles = file.filter(
        (file) =>
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
      );
      if (validFiles.length !== file.length && (!e?.target?.files[0]?.type==="video/quicktime"||!e?.target?.files[0]?.type==="video/mp4"||!e?.target?.files[0]?.type==="video/mov"||!e?.target?.files[0]?.type==="video/heif"||!e?.target?.files[0]?.type==="video/mov")) {
        setErrors({fileSelect:"unsupport file"})
  }
       
       else if (e.target.files[0]?.type ==="video/quicktime"||  e.target.files[0]?.type ==="video/mp4") {
        if (!video) {
          setImages([]);
  
          handleVideoFile(file[0]);
        } else {
          setErrors({ fileSelect: "Only one video allowed." });
        }
      } else if(validFiles.length === file.length){
        setVideo(null);
        handleImageFiles(file);
      } else{
   
        setErrors({
          fileSelect: "Unsupported file format.",
        });
      }
    };
  
   
    const handleImageFiles = (files) => {
      const minSizePhoto = 25 * 1024;
      const maxSizePhoto = 5 * 1024 * 1024;
      const validSizeFiles = files.filter(
        (file) => file.size >= minSizePhoto && file.size <= maxSizePhoto
      );
      if (validSizeFiles.length !== files.length) {
        setErrors({fileSelect:"Photo size must be between 25 Kilobytes to 5 Mb."});
        return;
      }
      if (images.length + validSizeFiles.length > 5) {
        setErrors({fileSelect:"Maximum 5 images allowed."});
        return;
      }
      validSizeFiles?.forEach((file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const img = new Image();
          img.src = e.target.result;
          img.onload = handleImageLoad(file);
        };
        reader.readAsDataURL(file);
      });
    };
    const handleImageLoad = (file) => () => {
      setImages((prevImages) => [
        ...prevImages,
        { url: URL.createObjectURL(file), type: file.type },
      ]);
      setPost((prevPost) => [...prevPost, file]);
    };
    











  
  const resizeAndCompressThumbnail = (canvas, maxWidth, maxHeight, quality = 0.7) => {
    const ctx = canvas.getContext("2d");
    const scale = Math.min(maxWidth / canvas.width, maxHeight / canvas.height);
  
    const newWidth = canvas.width * scale;
    const newHeight = canvas.height * scale;
  
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.width = newWidth;
    resizedCanvas.height = newHeight;
  
    const resizedCtx = resizedCanvas.getContext("2d");
    resizedCtx.drawImage(canvas, 0, 0, newWidth, newHeight);
  
    // Compress the image by setting a lower quality (0.7 for JPEG)
    return resizedCanvas.toDataURL("image/jpeg", quality);
  };
  
  const handleVideoFile = (file) => {
    const maxSizeVideo = 50 * 1024 * 1024; // 50 MB
    const maxVideoDuration = 30; // 30 seconds
  
    if (file.size <= maxSizeVideo) {
      const video = document.createElement("video");
      video.preload = "metadata";
  
      video.onloadedmetadata = function () {
        if (video.duration <= maxVideoDuration) {
          setVideo({ url: URL.createObjectURL(file), type: "video" });
  
          video.currentTime = 0;
  
          video.onseeked = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
  
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
  
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
            // Resize and compress the thumbnail
            const compressedThumbnailDataUrl = resizeAndCompressThumbnail(canvas, 300, 300, 0.7);
  
            // Set the thumbnail URL
            setThumbnail(compressedThumbnailDataUrl);
  
            // Clean up video URL object
            URL.revokeObjectURL(video.src);
  
            // Set the file for post or upload
            setPost(file);
  
            console.log({ thumbnail: compressedThumbnailDataUrl });
          };
        } else {
          setErrors({
            fileSelect: "video duration must be less than 30 seconds & video size must be less than 50 mb.",
          });
        }
      };
  
      video.src = URL.createObjectURL(file);
    } else {
      setErrors({ fileSelect: "video duration must be less than 30 seconds & video size must be less than 50 mb." });
    }
  };
  
  
  

  const handleCancelImage = (index) => {
   
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newPost = [...post];
    newPost.splice(index, 1);
    setPost(newPost);
  };
  const handleCancelVideo = () => {
    setPost([]);
    setErrors({});
    setVideo(null);
  };

  console.log({ video }, post)

  useEffect(() => {
    const fetchData = async () => {

      const formData = {
        email: email,
        fullName: fullName

      };

      try {
       
        const response = createweb(formData).then((response) =>
          // console.log(response?.data?.userId, "data")
    {
      if(response?.data?.userId){
        const IdVal = localStorage.getItem("userid");

        const formDataVal = {
          userProfileId: IdVal,
          profileClickBy:response?.data?.userId,
         guestName:fullName
        };
        try {
          const response = USERPROFILECLICK(formDataVal).then((response) =>
            console.log(response, "data")
      
          );
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          // const data = await response.json();
          // console.log('Payment Intent created successfully:', data);
          // Handle the response data here
  
        } catch (error) {
          console.error('Error creating Payment Intent:', error);
          // Handle any errors here
        }
      }
    }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Payment Intent created successfully:', data);
        // Handle the response data here
     
      } catch (error) {
        console.error('Error creating Payment Intent:', error);
        // Handle any errors here
      }
   
    };

    fetchData(); // Call the async function
  }, [navigate])

console.log(sayThanks === "" && !images && !video)
  console.log(sayThanks === "" || images?.length, "lklkkl",images?.length < 5,images,errors)
  return (
    <div className="w-full flex flex-col text-center  pb-8 gap-4 ">
      <div className="flex flex-col gap-5">
        <div
          className={`flex    ${isMobile ? "gap-2 justify-center" : "gap-1 justify-between"
            }`}>
          {reviewTypes.map((items) => {
            return (
              <button
                key={items?.value}
                className={`flex flex-col border border-[#979797] h-[5rem] w-[5.4rem] rounded-[0.625rem] justify-end gap-0.5 py-3 border-opacity-[30%] items-center ${items.value === gratitude
                  ? "bg-gradient-to-br from-[#FF8245] to-[#524CBB]"
                  : ""
                  }`}
                onClick={() => handleGratitudeClick(items.value)}
                value={items.value}>
                <img src={items.src} alt="" className="w-[2rem] h-[1.5rem]" />
                <p
                  className={`${items.value === gratitude ? "text-white" : "text-[#1D1546]"
                    } font-medium text-xs hover:cursor-pointer`}>
                  {items.label}
                </p>
              </button>
            );
          })}
        </div>
      </div>
      <TextField
        id="review-title"
        label="Review Title"
        type="text"
        required
        value={userDetails?.userName !==undefined ?titleView:""}
        disabled
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: "Plus Jakarta Sans",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
          },
        }}
      />
      <div className="relative w-full">
        <TextField
          id="Say thanks" // ID for the TextField
          label="Say thanks" // Label for the input field
          placeholder="If you'd like, use this space to give some words of thanks." // Fixed placeholder
          type="text"
          multiline
          rows={4}
          maxLength={250}
          inputProps={{
            maxLength: 250,
          }}
          required
          value={sayThanks}
          onChange={handleSayThanksChange}
          error={!!errors.sayThanks}
          helperText={errors.sayThanks}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              fontFamily: "Plus Jakarta Sans",
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderRadius: "10px",
            },
          }}
        />
        <img
          src={gallery}
          alt=""
          className={`absolute  right-[8px]  w-[25px] h-[25px] cursor-pointer  ${errors?.sayThanks ? "bottom-[30px]" : "bottom-[8px]"
            }`}
          onClick={() => {
            document.getElementById("add-post").click();
          }}
        />
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png, video/mp4, video/mov, video/heic, video/heif,.mov,.heic"
          id="add-post"
          style={{ display: "none" }}
          multiple={true}
          onChange={handleFileSelect}
          className="absolute bottom-[8px] right-[8px]  w-[25px] h-[25px] cursor-pointer"
          {...(images?.length < 5 ? { multiple: true } : {})}
        />

      </div>
      {errors.fileSelect && (
        <p className="text-[#d32f2f] font-light text-xs mt-[3px] mr-[14px] ml-[14px] text-left">
          {errors.fileSelect}
        </p>
      )}
      <div className="flex flex-wrap gap-4">
        {images?.map((image, index) => (
          <div key={index} className="w-[100px] h-[100px] rounded-xl relative">
            <img
              src={image.url}
              alt=""
              className="h-full w-full object-cover rounded-xl"
            />
            <CancelIcon
              className="absolute top-[-10px] right-[-12px] text-[#979797] cursor-pointer"
              onClick={() => handleCancelImage(index)}
            />
          </div>
        ))}
      </div>
      {video && (
        <div className="w-[100px] h-[100px] rounded-xl relative">
          <video
            key={video.url}
            controls
            className=" h-full w-full object-cover rounded-xl">
            <source src={video.url} type="video/mp4" />
            <track kind="captions" src="#" label="English" /> Your browser does
            not support the video tag.
          </video>
          <CancelIcon
            className="absolute top-[-10px] right-[-12px] text-[#979797]"
            onClick={handleCancelVideo}
          />
        </div>
      )}

      {addGift && (
        <div className="flex flex-col gap-1">
          <input
          onKeyPress={(e) => {
            if (e.key === '.' || e.key === ',' || e.key === 'e') {
              e.preventDefault(); // Prevent the dot (.) and other invalid characters
            }
          }}
          onPaste={(e) => {
            const pastedData = e.clipboardData.getData('text');
            if (pastedData.includes('.') || pastedData.includes(',') || isNaN(pastedData)) {
              e.preventDefault(); // Prevent pasting if it contains a dot or non-numeric data
            }
          }}
            type="number"
            id="amount"
            min={0}
            step={1}
            className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%] font-light h-[3.125rem] text-base p-[0.625rem] ${amountValidationError ? "border-red-500" : ""
              }`}
            placeholder="Enter amount (in Dollars)"
            value={selectedAmount || ""}
            onChange={handleAmountChange}
          />
          {amountValidationError && (
            <p className="w-full self-start  text-red-500 text-sm text-left">
              {amountValidationError}
            </p>
          )}
          <div className="flex flex-col gap-2 mt-6">
            {paymentMethods?.map((items) => {
              return (
                <>
                  <button
                    className={`w-full border-[1px] border-[#979797] rounded-[0.625rem] border-opacity-[30%]  h-[3.125rem] flex items-center gap-2 p-[0.625rem] ${paymentMethod === items?.value ? "bg-[#e0e0e0] border-[1px]  border-[#000000]" : "bg-white"
                      }`}
                    onClick={() => {
                      handlePaymentClick(
                        stripePromise,
                        selectedAmount,
                        items?.value
                      );
                    }}
                    value={items?.value}
                    key={items?.value}>
                    <img src={items.src} alt="" className="w-[1.5rem] h-[1.5rem]" />
                    <p className="text-[#1D1546] font-medium	text-sm	">
                      {items.label}
                    </p>

                  </button>
                  {/* {selectedAmount && */}
                  <p className="w-full self-start  text-green-700 text-sm text-left">
                    {`You will be charged ${items?.amount}`}

                  </p>

                </>
              );
            })}</div>
        </div>
      )}
      {stripePromise && clientSecret && checkout ? (
        <Elements
          stripe={stripePromise}
          key={clientSecret}
          options={{ clientSecret }}>
          <CheckoutForm msg={message}/>
        </Elements>
      ) : null}
 {message?.fileSelect && (
        <p className="text-[#d32f2f] font-light text-xs mt-[3px] mr-[14px] ml-[14px] text-left">
          {message}
        </p>
      )}
      {!addGift &&
        <button
          type="button"
          className="flex flex justify-center gap-3 border border-[#FF6F29] w-full h-[3.75rem]  rounded-[0.625rem] items-center"
          onClick={handleGiftClick}>
          <img src={gift} alt="" className="w-[1.313] h-[1.625rem]" />
          <p className="text-[#FF6F29] font-normal text-sm ">Add Gift</p>
        </button>}

      {!selectedAmount && (
        load?
        
        <div>
          <CircularProgress sx={{color:"rgb(255 111 41)",width:"30px",height:"30px "}}/>
          </div>:
        <button
          className={`flex justify-center items-center w-full h-[3.75rem] rounded-[0.625rem] 
          ${(sayThanks !== "" || images?.length >0 || video) ? "bg-[#FF6F29] cursor-pointer" : "bg-[lightgrey] cursor-not-allowed"}`}

          onClick={(sayThanks !== "" || images?.length >0 || video) ?  handleSubmit:null}
          disabled={(sayThanks !== "" || images?.length >0 || video)?false:true}
        >
          <p className="text-white font-bold text-lg">Submit</p>
        </button>
      )}

    { stripePromise && selectedAmount &&
      <Elements
        stripe={stripePromise}
        options={{ 
          mode: "payment",
          amount: parseFloat(feeAmount) * 100,
          currency: "usd",
          paymentMethodTypes: ["card"],
          // onBehalfOf: userDetails?.stripeAccountId,
          }}>
          <ApplePayCheckout selectedAmount={selectedAmount} feeAmount={feeAmount} payerDetails={{email,fullName}} />
      </Elements>
    }



    </div>

  );
};

export default Review;
