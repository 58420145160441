import React from "react";
import googlePlay from "../Assets/google-play.png";
import appstore from "../Assets/app-store.png";
import CancelIcon from "@mui/icons-material/Cancel";

const DownloadBanner = ({ visibility = () => {} }) => {
  const redirectToGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.de.grati",
      "_blank"
    );
  };

  const redirectToAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/grati-community/id6464380120",
      "_blank"
    );
  };
  const handleVisibility = () => {
    visibility(false);
  };
  return (
    <div className=" top-[15px] z-50 flex  h-fit w-[95%] pt-[2rem]">
      <div className="flex bg-white w-full justify-between  self-center rounded-[12px] p-2">
        <button
          className="flex w-[10rem] h-[3rem] items-center justify-around bg-white shadow-2xl rounded-3xl"
          onClick={redirectToGooglePlay}>
          <img src={googlePlay} alt="Google Play Store" />
        </button>
        <button
          className="flex w-[10rem] h-[3rem] items-center justify-around bg-white shadow-2xl rounded-3xl"
          onClick={redirectToAppStore}>
          <img src={appstore} alt="Apple App Store" />
        </button>
        {/* <CancelIcon
          className="absolute top-[-10px] right-[-10px] text-[#979797]"
          onClick={handleVisibility}
        /> */}
      </div>
    </div>
  );
};

export default DownloadBanner;
