import React, { useState } from "react";
import Joi from "joi";
import { TextField } from "@mui/material";
import PaymentOptions from "./payment_options";
import Review from "./review";

const schema = Joi.object({
  fullName: Joi.string().trim().min(1).max(30).required().label("Full Name"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email")
    .messages({
      "string.empty": "{#label} is not allowed to be empty",
      "string.email": "Please enter a valid email",
    }),
});
// review is basically gift type
// wrong naming
const PayerDetails = ({ review ,userDetails}) => {
  console.log({userDetails})
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [giveGift, setGiveGift] = useState("");
  const [errors, setErrors] = useState({});

  const handleGratitudeClick = () => {
    const validationResults = schema.validate(
      { fullName, email },
      { abortEarly: false }
    );

    if (validationResults.error) {
      const newErrors = {};
      validationResults.error.details.forEach((detail) => {
        newErrors[detail.context.key] = detail.message;
      });
      setErrors(newErrors);
    } else {
      setGiveGift(review);
    }
  };

  const handleName = (e) => {
    setFullName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, fullName: "" }));
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
  };

  return (
    <div className="w-[95%]">
      {giveGift.length === 0 && (
        <div className="flex flex-col gap-5 w-contain px-[1rem] items-center">
          <TextField
            className="textfield"
            max={20}
            id="fullName"
            label="Full Name"
            type="text"
            style={{ width: "19rem" }}
            required
            value={fullName}
            onChange={handleName}
            error={!!errors.fullName}
            helperText={errors.fullName}
            sx={{
              "& .MuiFormHelperText-root.Mui-error": {
                textAlign: "left", marginLeft: "0px"
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Plus Jakarta Sans",
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px",
              },
            }}
          />
          <TextField
            className="textfield"
            id="email"
            label="Email"
            type="email"
            style={{ width: "19rem" }}
            required
            value={email}
            onChange={handleEmail}
            error={!!errors.email}
            helperText={errors.email}
            sx={{
              "& .MuiFormHelperText-root.Mui-error": {
                textAlign: "left", marginLeft: "0px"
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Plus Jakarta Sans",
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px",
              },
            }}
          />

          <button
            className="flex flex justify-center  bg-[#FF6F29] w-[19rem] h-[3.75rem]  rounded-[0.625rem] items-center"
            onClick={handleGratitudeClick}>
            <p className="text-white font-bold text-lg">Submit</p>
          </button>
        </div>
      )}
      {giveGift === "gift" && (
        <PaymentOptions fullName={fullName} email={email} userDetails={userDetails}/>
      )}
      {giveGift.length !== 0 && giveGift !== "gift" && (
        <Review review={giveGift} fullName={fullName} email={email} />
      )}
    </div>
  );
};

export default PayerDetails;
