import axios from "axios";
const fetchClient = () => {
  let instance = axios.create();
  instance.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer guest`;
    return config;
  });
  return instance;
};
export default fetchClient();
